import React from "react";

const Quote = ({ text, author }) => {
  return (
    <blockquote className="quote">
      <p className="text">{text}</p>
      {author && <small>{author}</small>}
    </blockquote>
  );
};

export default Quote;
