import React from "react";
import Link from "./link";

const ExternalLinks = ({ title, description, links }) => {
  return (
    <aside className="external-links external-links--multiple">
      {(title || description) && (
        <div className="links-header">
          {title && <p className="title">{title}</p>}
          {description && <p>{description}</p>}
        </div>
      )}
      {links && (
        <ul>
          {links.map(link => {
            return (
              <li key={link._key}>
                <Link link={link}></Link>
              </li>
            );
          })}
        </ul>
      )}
    </aside>
  );
};

export default ExternalLinks;
