import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { Link } from "gatsby";
import BlockContent from "@sanity/block-content-to-react";
import File from "../components/blocks/file";
import Quote from "../components/blocks/quote";
import Video from "../components/blocks/video";
import Figure from "../components/blocks/figure";
import ExternalLink from "../components/blocks/external-link";
import ExternalLinks from "../components/blocks/external-links";

function getFigure(props) {
  if (props.node && props.node.image) {
    return (
      <Figure
        figure={props.node}
        options={{
          width: 600,
        }}
      />
    );
  }

  return "";
}

const serializers = {
  container: "section",
  types: {
    figure: props => getFigure(props),
    blockImage: props => getFigure(props),
    blockQuote: props => {
      if (props.node.text) {
        return (
          <Quote text={props.node.text} author={props.node.author}></Quote>
        );
      }

      return "";
    },
    blockFile: props => {
      if (props.node && props.node.file) {
        return <File node={props.node}></File>;
      }

      return "";
    },
    blockLink: props => {
      if (props.node && props.node.link) {
        return <ExternalLink link={props.node.link}></ExternalLink>;
      }

      return "";
    },
    blockLinks: props => {
      if (props.node && props.node.links) {
        return (
          <ExternalLinks
            title={props.node.title}
            description={props.node.description}
            links={props.node.links}
          ></ExternalLinks>
        );
      }

      return "";
    },
    youtube: props => {
      return (
        <Video
          options={{
            width: 600,
            aspectRatio: 16 / 9,
          }}
          youtube={props.node.url}
        ></Video>
      );
    },
    block: props => {
      const { style = "normal" } = props.node;

      if (style === "blockquote") {
        return <aside>{props.children}</aside>;
      }

      // Fall back to default handling
      return BlockContent.defaultSerializers.types.block(props);
    },
  },
  marks: {
    link: ({ children, mark }) => {
      const href = mark?.href || "";
      return href.includes("https://www.flyingcolours.org.au") ? (
        <Link to={href}>{children}</Link>
      ) : (
        <OutboundLink rel="noreferrer" target="_blank" href={href}>
          {children}
        </OutboundLink>
      );
    },
  },
};

export default serializers;
