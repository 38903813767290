import React from "react";
import filesize from "filesize";

const File = ({ node }) => {
  const file = node.file;

  return (
    <div className="file">
      <div className="details">
        <p className="title">{node.title}</p>
        <p>Printable {filesize(file.asset.size, { round: 0 })} PDF</p>
      </div>
      <a href={file.asset.url} className="button" download>
        Download
      </a>
    </div>
  );
};

export default File;
