/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import parse from "url-parse";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { Link as L } from "gatsby";
import Image from "../blocks/image";
import { Link as LinkIcon, PDF } from "../../util/icons";

const Link = ({ link }) => {
  let url,
    domain,
    title,
    description,
    image = "",
    isPage = false;

  if (!link.page && !link.url) {
    // No link or page. Return empty string.
    return "";
  }

  if (link.page) {
    isPage = true;
    if (link.page._type === "category") {
      url = `/${link.page.slug.current}/`;
    } else {
      url = `/${link.page._type}s/${link.page.slug.current}/`;
    }

    domain = "flyingcolours.org.au";
    title = link.page.title;
    description = link.page.subtitle;
    image = (
      <Image
        image={link.page.poster.image}
        options={{
          width: 120,
          heght: 120,
          placeholder: "blurred",
        }}
        rounded={true}
      ></Image>
    );
  } else if (link.url) {
    url = link.url.resolvedUrl || link.url.url;
    const urlParts = parse(url, true);
    domain = urlParts.hostname.replace("www.", "");
    title = link.url?.meta?.title;
    description = link.url?.meta?.description;
    if (link.url?.openGraph?.image) {
      image = link.url?.openGraph?.image && (
        <img src={link.url.openGraph.image} alt="" />
      );
    } else if (url.slice(-4).toLowerCase() === ".pdf") {
      image = <PDF className="icon" />;
    }
  }

  const linkBody = (
    <>
      <div className="link-image">{image}</div>
      <div className="link-info">
        <p className="title">{title}</p>
        <p className="domain">
          {domain}
          {!isPage && <LinkIcon className="icon icon--inline" />}
        </p>
        <p className="description">{description}</p>
      </div>
    </>
  );

  return isPage ? (
    <L to={url} className="link">
      {linkBody}
    </L>
  ) : (
    <OutboundLink target="_blank" href={url} className="link">
      {linkBody}
    </OutboundLink>
  );
};

export default Link;
