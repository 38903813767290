import React from "react";
import Link from "./link";

const ExternalLink = ({ link }) => {
  return (
    <aside className="external-links external-links--single">
      <Link link={link}></Link>
    </aside>
  );
};

export default ExternalLink;
